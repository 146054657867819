import axios from 'axios';

const token =
  document.cookie
    .split('; ')
    .find((row) => row.startsWith('PDTOKEN='))
    ?.split('=')[1] ||
  (process.env.NODE_ENV === 'development' ? process.env.DEV_COOKIE : null);

if (!token) {
  window.location.href = process.env.RDS_LOGIN_LINK;
}

const api = axios.create({
  baseURL: process.env.API_BASE_URL,
});

export const apiPrivate = axios.create({
  baseURL: process.env.API_BASE_URL,
  headers: {
    Authorization: token,
  },
});

export const apiPastoral = axios.create({
  baseURL: process.env.API_PASTORAL_URL,
  headers: {
    Authorization: process.env.API_PASTORAL_API_KEY,
  },
});

export default api;
